import onmount from 'onmount';
import Rails from '@rails/ujs';
import renderIndicators from '@modules/render-indicators';
import getQuestionPositions from '@modules/question-positions';
import { debounce } from '@modules/custom';

onmount('[data-js-exam-panel]', function () {
  const self = this;
  const iframe = this.querySelector('iframe');
  const previewProgressIndicator = this.querySelector('[data-js-preview-progress-indicator]');
  const { assignmentId, identifierId } = iframe.dataset;
  const positionsKey = `preview-positions-${identifierId}`;

  let PDFViewerApplication;
  let PDFViewerApplicationOptions;

  function handleDocumentLoad() {
    const scale = localStorage.getItem('pdf-scale');
    if (scale) PDFViewerApplication.pdfViewer.currentScaleValue = scale;
  }

  function handleSubquestionClick() {
    const subquestionId = this.dataset.id;
    const subquestion = document.querySelector(`[data-js-subquestion][data-subquestion-id='${subquestionId}']`);
    const currentPosition = subquestion.offsetTop;

    window.scrollTo(0, currentPosition - 10);
  }

  function clearErrorTemplate() {
    const error = self.querySelector('[data-js-error]');
    if (error) error.outerHTML = '';
  }

  function displayQuestionBox(page) {
    const { pages } = iframe.dataset;
    const pageOffset = PDFViewerApplication.pdfViewer.pdfDocument._pdfInfo.numPages - pages; // eslint-disable-line no-underscore-dangle
    getQuestionPositions(positionsKey, identifierId).then((data) => {
      renderIndicators(iframe, page, data, pageOffset, handleSubquestionClick);
    });
  }

  function bindEventListeners() {
    if (!PDFViewerApplication.initialized) {
      return setTimeout(bindEventListeners, 1);
    }

    PDFViewerApplication.eventBus.on('documentloaded', () => {
      handleDocumentLoad();
    });

    PDFViewerApplication.eventBus.on('pagerendered', (e) => {
      displayQuestionBox(e.pageNumber);
    });

    PDFViewerApplication.eventBus.on('pagechanging', (e) => {
      const documentId = PDFViewerApplicationOptions.get('documentId');
      localStorage.setItem(`generator/${documentId}/page`, e.pageNumber);
    });

    PDFViewerApplication.eventBus.on('scalechanged', (e) => {
      localStorage.setItem('pdf-scale', e.value);
    });
    return true;
  }

  function loadDocument(url) {
    clearErrorTemplate();
    PDFViewerApplicationOptions.set('documentId', assignmentId);
    const page = parseFloat(localStorage.getItem(`generator/${assignmentId}/page`) || 1);
    PDFViewerApplication.initialBookmark = `page=${page}`;
    PDFViewerApplication.setTitleUsingUrl(decodeURIComponent(url));
    PDFViewerApplication.open(url);
  }

  function renderErrorTemplate(errorTemplate) {
    const error = self.querySelector('[data-js-error]');
    if (error) {
      error.outerHTML = errorTemplate;
    } else {
      self.insertAdjacentHTML('afterbegin', errorTemplate);
    }
  }

  const fetchPreview = debounce(() => {
    if (!document.body.contains(self)) return;

    const { url } = this.dataset;
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        iframe.dataset.pages = data.pages;
        if (data.url) {
          previewProgressIndicator.classList.add('d-none');
          loadDocument(data.url);
        } else if (data.error_template) {
          renderErrorTemplate(data.error_template);
        } else {
          fetchPreview();
        }
      }
    });
  }, 2500);

  function init() {
    const { url } = iframe.dataset;
    localStorage.removeItem(positionsKey);
    ({ PDFViewerApplication, PDFViewerApplicationOptions } = iframe.contentWindow);

    if (!PDFViewerApplication || !PDFViewerApplication.initialized || iframe.contentDocument.readyState !== 'complete') {
      setTimeout(init, 100);
      return;
    }

    if (iframe.dataset.disableRange) PDFViewerApplicationOptions.set('disableRange', true);

    bindEventListeners();
    if (url) {
      loadDocument(url);
    } else {
      setTimeout(fetchPreview, 5000);
    }
  }

  function handleQuestionChange() {
    previewProgressIndicator.classList.remove('d-none');
    localStorage.removeItem(positionsKey);
    setTimeout(fetchPreview, 5000);
  }
  this.handleQuestionChange = handleQuestionChange;

  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
  if (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive') {
    init();
  } else {
    iframe.addEventListener('load', init);
  }

  [
    'questionCreated', 'questionUpdated', 'questionDestroyed',
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed', 'coverUpdated',
    'assignmentUpdated'
  ].forEach((evt) => {
    document.addEventListener(evt, this.handleQuestionChange);
  });
}, function () {
  [
    'questionCreated', 'questionUpdated', 'questionDestroyed',
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed', 'coverUpdated',
    'assignmentUpdated'
  ].forEach((evt) => {
    document.removeEventListener(evt, this.handleQuestionChange);
  });
});
