{
  "de": {
    "js": {
      "redactor": {
        "accessibility-help-label": "RTF editor",
        "add-gap": "Loch hinzufügen",
        "add-head": "Titel hinzufügen",
        "add-variable": "Variable hinzufügen",
        "align": "Ausrichten",
        "align-center": "Zentrum ausrichten",
        "align-left": "Links ausrichten",
        "align-right": "Rechts ausrichten",
        "allow-microphone-access": "Bitte Mikrophonzugang gestatten",
        "audio": "Audioaufzeichnung",
        "background": "Hintergrund",
        "background-color": "Hintergrundfarbe",
        "blockquote": "Zitat",
        "bold": "Fett",
        "bold-abbr": "B",
        "bulletslist": "Punkte-Liste",
        "cancel": "Absagen",
        "caption": "Bildunterschrift",
        "center": "Mitte",
        "change": "Ändern",
        "change-picture": "Bild ändern",
        "chars": "Zeichen",
        "choose": "Wählen Sie",
        "close": "Schließen",
        "code": "Code",
        "delete": "Löschen",
        "delete-column": "Spalte löschen",
        "delete-head": "Titel löschen",
        "delete-row": "Zeile löschen",
        "delete-table": "Tabelle löschen",
        "deleted": "Durchgestrichen",
        "deleted-abbr": "S",
        "drawing": "Zeichnung",
        "edit": "Bearbeiten",
        "equation": "Gleichung",
        "error-accessing-microphone": "Es gab ein Problem beim Annähern an Ihr Mikrofon",
        "file": "Ablage",
        "filename": "Dateiname",
        "fontcolor": "Textfarbe",
        "format": "Textformat",
        "fullscreen": "Voller Bildschirm.",
        "gap": "Loch",
        "grade-formula-points": "Erreichte Punkte",
        "grade-formula-total": "Gesamtpunktzahl",
        "heading": "Rubrik",
        "heading1": "Rubrik 1",
        "heading2": "Rubrik 2",
        "heading3": "Rubrik 3",
        "heading4": "Rubrik 4",
        "heading5": "Rubrik 5",
        "heading6": "Rubrik 6",
        "horizontalrule": "Streifen",
        "image": "Bild",
        "image-position": "Position",
        "indent": "Einspringen",
        "insert": "Einfügen",
        "insert-column-left": "Spalte links einfügen",
        "insert-column-right": "Spalte rechts einfügen",
        "insert-row-above": "Zeile oben einfügen",
        "insert-row-below": "Zeile unterhalb einfügen",
        "insert-table": "Tabelle einfügen",
        "italic": "Kursiv",
        "italic-abbr": "I",
        "left": "Links",
        "link": "Verbindung",
        "link-edit": "Link-Bearbeiten",
        "link-in-new-tab": "Link in neuem Tab öffnen",
        "link-insert": "Link einfügen",
        "lists": "Lists",
        "make-recording-warning": "Zuerst eine Aufnahme machen",
        "maxWords": "Maximale Anzahl von Wörtern",
        "no-variables": "Keine Variablen definiert",
        "none": "Keine",
        "numberslist": "Nummerierte Liste",
        "optionList": "Antwortliste",
        "optional": "Fakultativ",
        "orderedlist": "Geordnete liste",
        "outdent": "Ausrücken",
        "paragraph": "Normaler Text",
        "pause": "Pause",
        "pre": "Code",
        "quote": "Zitat",
        "record": "Aufnahme",
        "redo": "Wiederholen",
        "remove": "Entfernen",
        "resume": "Weitermachen",
        "right": "Richtig",
        "save": "Speichern",
        "save as shortcut": "Als Schnellkommentar speichern",
        "shortcuts": "Schnelle Kommentare",
        "show shortcuts": "Kurze Kommentare anzeigen",
        "specialchars": "Besondere Zeichen",
        "stop": "Stopp",
        "stream-unavailable": "Video-Stream nicht verfügbar.",
        "subscript": "Tiefgestellt",
        "subscript-abbr": "Sub",
        "superscript": "Hochgestellt",
        "superscript-abbr": "Sup",
        "table": "Tabelle",
        "take-picture": "Fotografieren",
        "take-picture-warning": "Machen Sie zuerst ein Foto",
        "text": "Textfarbe",
        "text-color": "Textfarbe",
        "title": "Titel",
        "twemoji": "Emoji",
        "underline": "Unterstreichen",
        "underline-abbr": "U",
        "undo": "Rückgängig machen",
        "unlink": "Abkoppeln",
        "unorderedlist": "Nicht geordnete Liste",
        "upload": "Hochladen",
        "upload-change-label": "Ziehen Sie ein neues Bild zur Anpassung",
        "upload-label": "Dateien ziehen oder zum Hochladen anklicken",
        "variable": "Variabele",
        "webcam": "Webcam-Foto",
        "words": "Worte"
      }
    }
  }
}